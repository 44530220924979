var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('login-page-layout',[_c('div',{staticClass:"card bg-2"},[_c('h3',{staticClass:"card-head",on:{"click":_vm.showVerificationModal}},[_vm._v(" Forgot Password ")]),_c('b-form',{directives:[{name:"show",rawName:"v-show",value:(_vm.showEmailSection),expression:"showEmailSection"}],attrs:{"save-url":_vm.sentOtp,"save-params":_vm.model},on:{"success":_vm.showVerificationModal,"failure":_vm.formError},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loading = ref.loading;
return [_c('p',[_vm._v("Please enter your registered E-Mail ID for OTP verification.")]),_c('validated-input',{attrs:{"border-radius":"custom","disabled":loading,"rules":_vm.rules.email,"type":"email","placeholder":"E-mail","name":"Email"},model:{value:(_vm.model.email),callback:function ($$v) {_vm.$set(_vm.model, "email", $$v)},expression:"model.email"}}),_c('div',{staticClass:"fl-y-cc mt-4"},[_c('btn',{staticClass:"w-10r font-weight-bold",attrs:{"color":"secondary","border-radius":"custom","type":"submit","disabled":loading,"text":"Send OTP"}},[(loading)?_c('span',[_c('loading-animation'),_c('span',{staticStyle:{"vertical-align":"super"}},[_vm._v(" Verifying...")])],1):_vm._e()]),_c('p',{staticClass:"mt-3"},[_c('router-link',{attrs:{"to":"/auth/login/"}},[_vm._v("Back to Login")])],1)],1)]}}])}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showVerificationPage),expression:"showVerificationPage"}]},[_c('b-form',{ref:"otpForm",attrs:{"save-url":_vm.validateOtp,"save-params":_vm.model},on:{"success":_vm.otpVerified,"failure":_vm.formError},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var model = ref.model;
var loading = ref.loading;
return [_c('validated-input',{attrs:{"rules":_vm.rules.otp,"disabled":loading,"type":"number","name":"Otp","label":_vm.message},model:{value:(model.otp),callback:function ($$v) {_vm.$set(model, "otp", $$v)},expression:"model.otp"}}),(_vm.errorMessage)?_c('div',{staticClass:"bgc-danger card bs-5 mb-2",domProps:{"innerHTML":_vm._s(_vm.errorMessage)}}):_vm._e(),_c('div',{staticClass:"fl-x-cc my-4"},[_c('btn',{staticClass:"font-weight-bold",attrs:{"color":"secondary","id":"btn-4","border-radius":"custom","text":"Back","type":"button"},on:{"click":_vm.goBack}}),_c('btn',{staticClass:"ml-2 font-weight-bold",attrs:{"color":"secondary","border-radius":"custom","type":"submit","text":"Verify"}},[(loading)?_c('span',[_c('loading-animation'),_c('span',{staticStyle:{"vertical-align":"super"}},[_vm._v(" Verifying...")])],1):_vm._e()])],1)]}}])})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.changePasswordPage),expression:"changePasswordPage"}]},[_c('b-form',{ref:"otpForm",attrs:{"save-url":_vm.changePassword,"save-params":_vm.model},on:{"success":_vm.changePasswordSuccess,"failure":_vm.formError},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var model = ref.model;
var loading = ref.loading;
return [_c('validated-input',{attrs:{"type":"password","label":"Password","name":"Password","disabled":loading,"rules":{required:true}},model:{value:(model.new_password),callback:function ($$v) {_vm.$set(model, "new_password", $$v)},expression:"model.new_password"}}),_c('validated-input',{attrs:{"type":"password","label":"Confirm Password","name":"ConfirmPassword","disabled":loading,"rules":{required:true}},model:{value:(model.confirm_password),callback:function ($$v) {_vm.$set(model, "confirm_password", $$v)},expression:"model.confirm_password"}}),_c('div',{staticClass:"fl-x-cc my-4"},[_c('btn',{staticClass:"ml-2 font-weight-bold",attrs:{"color":"secondary","border-radius":"custom","type":"submit","text":"Change Password"}},[(loading)?_c('span',[_c('loading-animation'),_c('span',{staticStyle:{"vertical-align":"super"}},[_vm._v(" Verifying...")])],1):_vm._e()])],1)]}}])})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }